import { createRouter, createWebHistory } from "vue-router";
import config from "../config";

// Use the product map from config to create GTIN to component mappings
const gtinComponentMapSerialized = Object.assign(
  {
    default: () =>
      import("@/components/Serialized/default/DefaultComponent.vue"), // Your default component for serialized products
  },
  Object.fromEntries(
    Object.entries(config.productMap.serialized).map(([gtin, productId]) => [
      gtin,
      () => import(`@/components/Serialized/${productId}/${productId}.vue`), // Adjust the path and component name as needed
    ])
  )
);

const gtinComponentMapNonSerialized = Object.assign(
  {
    default: () =>
      import("@/components/NonSerialized/default/DefaultComponent.vue"), // Your default component for non-serialized products
  },
  Object.fromEntries(
    Object.entries(config.productMap.nonSerialized).map(([gtin, productId]) => [
      gtin,
      () => import(`@/components/NonSerialized/${productId}/${productId}.vue`), // Adjust the path and component name as needed
    ])
  )
);

const routes = [
  {
    path: "/validate/:ai_gtin/:gtin/:ai_batch/:batch_number/:ai_serial_number/:serial_number",
    name: "ValidateSerializedProduct",
    component: gtinComponentMapSerialized["default"], // Temporary component, will be replaced in the guard
    beforeEnter: (to, from, next) => {
      const gtin = to.params.gtin;
      const component =
        gtinComponentMapSerialized[gtin] ||
        gtinComponentMapSerialized["default"];
      to.matched[0].components.default = component;
      next();
    },
  },
  {
    path: "/validate/:ai_gtin/:gtin/:ai_batch/:batch_number",
    name: "ValidateNonSerializedProduct",
    component: gtinComponentMapNonSerialized["default"], // Temporary component, will be replaced in the guard
    beforeEnter: (to, from, next) => {
      const gtin = to.params.gtin;
      const component =
        gtinComponentMapNonSerialized[gtin] ||
        gtinComponentMapNonSerialized["default"];
      to.matched[0].components.default = component;
      next();
    },
  },
  // Add other routes here
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
